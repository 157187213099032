const menuItems = [
    {
        link: '/',
        label: 'Home',
        isExternal: false,
    },
    {
        link: 'https://blog.brianlusina.com',
        label: 'Blog',
        isExternal: true
    },
];

export default menuItems;
