import styled from 'styled-components';

export const SocialCardWrapper = styled.section``;

export const SocialCardTitle = styled.h2``;

export const SocialCardList = styled.ul``;

export const SocialCardListItem = styled.li``;

export const SocialCardLink = styled.a``;

export const SocialCardSpan = styled.span``;
