import styled from 'styled-components';

export const MenuContainer = styled.nav``;

export const MenuTitle = styled.h2``;

export const MenuList = styled.ul``;

export const MenuListItem = styled.li``;

export const MenuCloseButton = styled.a``;
